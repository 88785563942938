import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default ({ data: { prismicIndex } }) => (
  <React.Fragment>
    <Helmet htmlAttributes={{ lang : 'en' }}>
      <meta name="description" content="This website is an introduction to me, Trevor Fawcett." />
      <title>Trevor Fawcett - A Work in Progress</title>
    </Helmet>
    <article>
      <h1>{prismicIndex.data.title.text}</h1>
      <section>
        <h2>about me</h2>
        <div dangerouslySetInnerHTML={{ __html: prismicIndex.data.about.html }} />
      </section>
      <section>
        <h2>my projects</h2>
        <div dangerouslySetInnerHTML={{ __html: prismicIndex.data.projects.html }} />
      </section>
      <section>
        <h2>contact me</h2>
        <div dangerouslySetInnerHTML={{ __html: prismicIndex.data.contact.html }} />
      </section>
    </article>
  </React.Fragment>
)

export const pageQuery = graphql`
  query IndexQuery {
    prismicIndex {
      data {
        title {
          text
        },
        about {
          html
        },
        projects {
          html
        },
        contact {
          html
        }
      }
    }
  }
`